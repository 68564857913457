import { AvailabilityResponse, NylasSchedulerBookingDataWithFlatFields, NylasEvent, UISettingsResponse } from '@/common/types';
import { NylasSchedulerStoreType } from '../../../components';
import type { NylasErrorResponse, NylasResponse, NylasSchedulerBookingData, NylasSchedulerResponse, Timeslot } from '@nylas/core';
import { APIErrorType, Errors } from '@/connector/nylas-scheduler-connector/errors';
import { addDaysToCurrentDate } from '@/utils/utils';
import i18next from '@/utils/i18n';

type NylasSchedulerAPIConnectorOptions = {
  schedulerAPIURL: string;
  schedulerStore: NylasSchedulerStoreType;
  sessionId?: string;
  configId?: string;
  slug?: string;
  clientId?: string;
};

/**
 * NylaSchedulerConnector
 * This class is used to make API requests to the scheduler.
 */
export class NylaSchedulerAPIConnector {
  private schedulerStore: NylasSchedulerStoreType;
  private schedulerAPIURL: string;
  private sessionId: string | undefined;
  private configId: string | undefined;
  private slug: string | undefined;
  private clientId: string | undefined;
  private errors = new Errors();

  constructor({ schedulerAPIURL, schedulerStore, sessionId, configId, slug, clientId }: NylasSchedulerAPIConnectorOptions) {
    this.schedulerStore = schedulerStore;
    this.schedulerAPIURL = schedulerAPIURL;
    this.sessionId = sessionId;
    this.configId = configId;
    this.slug = slug;
    this.clientId = clientId;
  }

  private getHeaders() {
    return this.sessionId
      ? {
          Authorization: `Bearer ${this.sessionId}`,
        }
      : {};
  }

  /**
   * Makes an API request to the scheduler.
   * @param path The path to the API endpoint.
   * @param method The HTTP method.
   * @param body The request body (if any).
   * @returns {Promise<T>}
   */
  public async makeAPIRequest<T>(path: string, method: string, body: string | undefined, headers = {}): Promise<NylasResponse<T>> {
    try {
      const schedulerURL = new URL(this.schedulerAPIURL);
      const version = process.env.PACKAGE_VERSION || 'latest';
      schedulerURL.pathname = path;
      const response = await fetch(decodeURIComponent(schedulerURL.toString()), {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Origin': window.location.origin,
          'X-Source': 'nylas-scheduling',
          'X-Nylas-Web-Elements-Version': version,
          ...headers,
        },
        body,
      });
      // The server returns a json object for errors: eg.
      // {
      //     "request_id": "<request_id>",
      //     "error": {
      //         "type": "not_found_error",
      //         "message": "Session not found"
      //     }
      // }
      const data = await response.json();
      return data as NylasResponse<T>;
    } catch (error: any) {
      // NOTE: current server implementation doesn't return a JSON object for errors on some endpoints
      // handle this case by returning the error response as a string to be handled downstream
      return {
        error: {
          message: error.message,
          title: 'API request failed',
          type: 'api',
        },
      } as NylasErrorResponse;
    }
  }

  private getErrorMessage(error: NylasErrorResponse['error']) {
    let errorMessage = error?.message || error?.title || 'Something went wrong';
    if (error?.type === 'provider_error') {
      errorMessage = error?.provider_error?.error?.message || error?.provider_error?.error?.title || 'Something went wrong';
    }
    return errorMessage;
  }

  public setConfigId(configId: string) {
    this.configId = configId;
  }

  /**
   * Selects a date in the scheduler.
   */
  public selectDate(date: Date) {
    this.schedulerStore.set('selectedDate', date);
    this.schedulerStore.set('selectedTimeslot', null);
  }

  /**
   * Selects a time in the scheduler.
   */
  public selectTime(time: Timeslot) {
    this.schedulerStore.set('selectedTimeslot', time);
  }

  /**
   * Sets the timezone in the scheduler.
   */
  public selectTimezone(timezone: string) {
    this.schedulerStore.set('selectedTimezone', timezone);
  }

  /**
   * Sets the language in the scheduler.
   */
  public selectLanguage(language: string) {
    this.schedulerStore.set('selectedLanguage', language);
    i18next.changeLanguage(language);
  }

  /**
   * Toggles showBookingForm
   * @param value boolean
   */
  public async toggleAdditionalData(value: boolean) {
    if (!value) {
      // Refetch availability
      await this.refetchAvailability();
    }
    this.schedulerStore.set('showBookingForm', value);
  }

  /**
   * Set/update the name of the participant booking the event.
   */
  public setParticipantName(name: string) {
    const { bookingInfo } = this.schedulerStore.state;
    this.schedulerStore.set('bookingInfo', {
      ...bookingInfo,
      primaryParticipant: {
        ...(bookingInfo?.primaryParticipant as NylasSchedulerBookingData['primaryParticipant']),
        name,
      },
    });
  }

  /**
   * Set/update the email of the participant booking the event.
   */
  public setParticipantEmail(email: string) {
    const { bookingInfo } = this.schedulerStore.state;
    this.schedulerStore.set('bookingInfo', {
      ...bookingInfo,
      primaryParticipant: {
        ...(bookingInfo?.primaryParticipant as NylasSchedulerBookingData['primaryParticipant']),
        email,
      },
    });
  }

  private async refetchAvailability() {
    const today = new Date();
    // Refetch availability
    const startTime = new Date(today.getFullYear(), today.getMonth(), 1).getTime() / 1000;
    const startTimeWithOffset = startTime < today.getTime() / 1000 ? Math.floor(today.getTime() / 1000) : startTime;
    const endTime = new Date(today.getFullYear(), today.getMonth() + 1, 1).getTime() / 1000;
    const result = await this.getAvailability(startTimeWithOffset, endTime);
    return result;
  }

  private async resetStoreStateAndFetchAvailability() {
    const today = new Date();
    // Refetch availability
    const result = await this.refetchAvailability();
    // Set selected date to first available date
    const firstAvailableDate = this.schedulerStore.get('availability').find((timeslot: any) => new Date(timeslot.start_time) > new Date());
    let _selectedDate = today;
    if (firstAvailableDate) {
      _selectedDate = firstAvailableDate.start_time;
    }
    this.schedulerStore.set('selectedDate', _selectedDate);
    // Reset store state
    this.schedulerStore.set('eventInfo', null);
    this.schedulerStore.set('showBookingForm', false);
    this.schedulerStore.set('selectedTimeslot', null);

    return result;
  }

  /**
   * Set reschedule booking id
   */
  public async setReschedule(bookingID: string) {
    this.schedulerStore.set('isLoading', true);
    const eventInfo = this.schedulerStore.state.eventInfo;
    if (eventInfo) {
      this.schedulerStore.set('reschedulingEventInfo', eventInfo);
    }
    this.schedulerStore.set('rescheduleBookingId', bookingID);

    // Set reschedule booking id
    const result = await this.resetStoreStateAndFetchAvailability().finally(() => {
      this.schedulerStore.set('isLoading', false);
    });

    return result;
  }

  /**
   * Set cancel booking id
   */
  public async setCancel(bookingID: string) {
    this.schedulerStore.set('cancelBookingId', bookingID);
  }

  /**
   * Set reject booking id
   */
  public async setReject(bookingID: string) {
    this.schedulerStore.set('rejectBookingId', bookingID);
  }

  public async resetCancel() {
    const result = await this.resetStoreStateAndFetchAvailability();
    this.schedulerStore.set('cancelBookingId', '');
    this.schedulerStore.set('rejectBookingId', '');
    this.schedulerStore.set('cancelledEventInfo', null);
    return result;
  }

  public async goBack() {
    this.schedulerStore.set('cancelBookingId', '');
    return;
  }

  public async resetConfirm() {
    const result = await this.resetStoreStateAndFetchAvailability();
    this.schedulerStore.set('organizerConfirmationBookingId', '');
    this.schedulerStore.set('confirmedEventInfo', undefined);
    return result;
  }

  /**
   * Book the selected timeslot.
   * @param data The booking info.
   * @returns {Promise<NylasResponse<NylasEvent>>}
   */
  public async bookTimeslot(data?: NylasSchedulerBookingDataWithFlatFields & { timeslot?: Timeslot }): Promise<NylasSchedulerResponse<NylasEvent>> {
    this.schedulerStore.set('isLoading', true);
    const { selectedTimeslot, selectedTimezone, bookingInfo, selectedLanguage } = this.schedulerStore.state;
    if (!data && !bookingInfo) {
      return { error: this.errors.component(i18next.t('createBookingErrorTitle')).no_booking_info() };
    }

    const timeslot = data?.timeslot || selectedTimeslot;
    if (!timeslot) {
      return { error: this.errors.component(i18next.t('createBookingErrorTitle')).no_timeslot_selected() };
    }

    const timezone = data && data?.timezone ? data?.timezone : selectedTimezone;
    const language = selectedLanguage || 'en-US';

    if (!timezone) {
      return { error: this.errors.component(i18next.t('createBookingErrorTitle')).no_timezone_selected() };
    }
    const order = this.schedulerStore.get('availabilityOrderEmails');
    let participantToBookWith = '';
    if (order.length > 0) {
      // Get the emails in the timeslot
      const emails = timeslot?.emails || [];
      for (let i = 0; i < order.length; i++) {
        if (emails.includes(order[i])) {
          participantToBookWith = order[i];
          break;
        }
      }
    }
    const addFields = {};
    Object.entries(bookingInfo?.additionalFields || {}).forEach(([key, entry]) => {
      addFields[key] = (entry as { value: string; type?: string }).value;
    });
    const primaryGuest = data ? data?.primaryParticipant : bookingInfo?.primaryParticipant;
    const guests = data ? data?.guests || [] : bookingInfo?.guests || [];
    const additional_fields = data ? data?.additionalFields : addFields;

    const headers = this.getHeaders();
    const configIdParam =
      !this.sessionId && this.configId
        ? `?configuration_id=${this.configId}`
        : !this.sessionId && this.slug && this.clientId
          ? `?slug=${this.slug}&client_id=${this.clientId}`
          : '';
    const url = `/v3/scheduling/bookings${configIdParam}`;

    const response = await this.makeAPIRequest<NylasEvent>(
      decodeURIComponent(url),
      'POST',
      JSON.stringify({
        participants: participantToBookWith ? [{ email: participantToBookWith }] : undefined,
        additional_fields,
        additional_guests: guests,
        guest: { ...primaryGuest },
        start_time: timeslot.start_time.getTime() / 1000,
        end_time: timeslot.end_time.getTime() / 1000,
        timezone: timezone,
        email_language: this.getTwoLetterLanguageCode(language),
      }),
      headers,
    );

    if ('error' in response) {
      this.schedulerStore.set('isLoading', false);
      const errorType = response.error?.type;
      let error = response.error;
      if (errorType && errorType in this.errors.api('Create Booking')) {
        const errorMessage = this.getErrorMessage(error);
        error = this.errors.api('Create Booking')[errorType as APIErrorType](errorMessage);
      }
      return { error };
    }

    if ('data' in response) {
      this.schedulerStore.set('eventInfo', response?.data);
    }

    this.schedulerStore.set('isLoading', false);
    return response;
  }

  /**
   * Get UI settings for the scheduler.
   */
  public async getUISettings(): Promise<NylasSchedulerResponse<UISettingsResponse>> {
    this.schedulerStore.set('isLoading', true);
    const headers = this.getHeaders();
    const configIdParam =
      !this.sessionId && this.configId
        ? `?configuration_id=${this.configId}`
        : !this.sessionId && this.slug && this.clientId
          ? `?slug=${this.slug}&client_id=${this.clientId}`
          : '';
    const url = `/v3/scheduling/ui-settings${configIdParam}`;

    const response = await this.makeAPIRequest<UISettingsResponse>(url, 'GET', undefined, headers);
    if ('error' in response) {
      this.schedulerStore.set('isLoading', false);
      const errorType = response.error?.type;
      let error = response.error;
      if (errorType && errorType in this.errors.api(i18next.t('getUISettingErrorTitle'))) {
        error = this.errors.api(i18next.t('getUISettingErrorTitle'))[errorType as APIErrorType](error?.message || error?.title || 'Something went wrong');
      }
      return { error };
    }
    if ('data' in response) {
      this.schedulerStore.set('configSettings', response.data);
    }
    this.schedulerStore.set('isLoading', false);
    return response;
  }

  private getTwoLetterLanguageCode(language: string) {
    return language.split('-')[0];
  }

  private getStartTimeWithMinBookingNotice(startTime: number) {
    const scheduler = this.schedulerStore.get('configSettings')?.scheduler;
    const min_booking_notice = scheduler?.min_booking_notice;
    if (!min_booking_notice) {
      return startTime;
    }
    const today = new Date().getTime();

    if (startTime < (today + min_booking_notice * 60 * 1000) / 1000) {
      return Math.floor((today + min_booking_notice * 60 * 1000) / 1000);
    } else {
      return startTime;
    }
  }

  private getEndTimeForAvailableDaysInFuture(endTime: number) {
    const today = new Date();
    const availableDaysInFuture = this.schedulerStore.get('configSettings')?.scheduler?.available_days_in_future;
    const endTimeForAvailableDaysInFuture = Math.floor(addDaysToCurrentDate(today, availableDaysInFuture).getTime() / 1000);
    const endTimeWithOffset = Math.min(endTimeForAvailableDaysInFuture, endTime);
    return endTimeWithOffset;
  }

  /**
   * Gets the availability for a page.
   * @param startTime The start time.
   * @param endTime The end time.
   * @returns {Promise<AvailabilityResponse>}
   */
  public async getAvailability(startTime: number = 0, endTime: number = 0): Promise<NylasSchedulerResponse<AvailabilityResponse>> {
    this.schedulerStore.set('isLoading', true);
    const params = new URLSearchParams();
    const now = new Date();
    const nowTime = now.getTime();

    if (endTime && endTime < nowTime / 1000) {
      this.schedulerStore.set('isLoading', false);
      const error = this.errors.component(i18next.t('getAvailabilityErrorTitle')).endtime_not_in_future();
      return { error };
    }

    // Calculate the start of the current month if startTime is not provided
    if (!startTime) {
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      startTime = Math.floor(startOfMonth.getTime() / 1000); // Convert to UNIX timestamp in seconds
    }

    // Calculate the end of the current month if endTime is not provided
    if (!endTime) {
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Setting day to 0 gets the last day of the previous month, so in this case, the last day of the current month
      endTime = Math.floor(endOfMonth.getTime() / 1000); // Convert to UNIX timestamp in seconds
    }

    endTime = this.getEndTimeForAvailableDaysInFuture(endTime);
    const startTimeWithMinBooking = this.getStartTimeWithMinBookingNotice(startTime);
    startTime = startTimeWithMinBooking;
    endTime = startTimeWithMinBooking > endTime ? startTimeWithMinBooking + 1 : endTime;

    params.append('start_time', encodeURIComponent(startTime.toString()));
    params.append('end_time', encodeURIComponent(endTime.toString()));
    if (this.configId && !this.sessionId) {
      params.append('configuration_id', encodeURIComponent(this.configId));
    } else if (this.slug && this.clientId && !this.sessionId) {
      params.append('slug', encodeURIComponent(this.slug));
      params.append('client_id', encodeURIComponent(this.clientId));
    }

    const rescheduleBookingId = this.schedulerStore.get('rescheduleBookingId');
    if (rescheduleBookingId) {
      params.append('booking_id', encodeURIComponent(rescheduleBookingId));
    }
    const queryString = params.toString();
    const url = `/v3/scheduling/availability${queryString ? `?${queryString}` : ''}`;
    const headers = this.getHeaders();
    const response = await this.makeAPIRequest<AvailabilityResponse>(decodeURIComponent(url), 'GET', undefined, headers);

    if ('error' in response) {
      this.schedulerStore.set('availability', []);
      this.schedulerStore.set('isLoading', false);
      const errorType = response.error?.type;
      let error = response.error;
      if (errorType && errorType in this.errors.api(i18next.t('getAvailabilityErrorTitle'))) {
        const errorMessage = this.getErrorMessage(error);
        error = this.errors.api(i18next.t('getAvailabilityErrorTitle'))[errorType as APIErrorType](errorMessage);
      }
      return { error };
    }

    if ('data' in response) {
      const availability =
        response.data?.time_slots?.map(timeslot => {
          return {
            ...timeslot,
            start_time: new Date(timeslot.start_time * 1000),
            end_time: new Date(timeslot.end_time * 1000),
          };
        }) || [];

      // Filter out timeslots that are in the past
      const availabilityTimeslotsFiltered = availability.filter(timeslot => timeslot.start_time.getTime() > nowTime);
      this.schedulerStore.set('availability', availabilityTimeslotsFiltered);
      const order = response.data?.order || [];
      this.schedulerStore.set('availabilityOrderEmails', order);
    }

    this.schedulerStore.set('isLoading', false);
    return response;
  }

  /**
   * Cancels a booking.
   * @param bookingId The booking ID.
   */
  public async cancelBooking(bookingId: string, reason: string): Promise<NylasSchedulerResponse<Partial<NylasEvent>>> {
    this.schedulerStore.set('isLoading', true);
    if (!bookingId) {
      return { error: this.errors.component(i18next.t('cancelBookingErrorTitle')).no_booking_id() };
    }
    const configIdParam =
      !this.sessionId && this.configId
        ? `?configuration_id=${this.configId}`
        : !this.sessionId && this.slug && this.clientId
          ? `?slug=${this.slug}&client_id=${this.clientId}`
          : '';
    const url = `/v3/scheduling/bookings/${bookingId}${configIdParam}`;
    const headers = this.getHeaders();
    const response = await this.makeAPIRequest<Partial<NylasEvent>>(
      decodeURIComponent(url),
      'DELETE',
      JSON.stringify({
        action: 'cancel',
        cancellation_reason: reason,
      }),
      headers,
    );

    if ('error' in response) {
      this.schedulerStore.set('isLoading', false);
      const errorType = response.error?.type;
      let error = response.error;
      if (errorType && errorType in this.errors.api(i18next.t('cancelBookingErrorTitle'))) {
        const errorMessage = this.getErrorMessage(error);
        error = this.errors.api(i18next.t('cancelBookingErrorTitle'))[errorType as APIErrorType](errorMessage);
      }
      return { error };
    }

    this.schedulerStore.set('cancelledEventInfo', {
      booking_id: bookingId,
    });
    this.schedulerStore.set('rescheduleBookingId', '');
    this.schedulerStore.set('isLoading', false);
    return response;
  }

  /**
   * Reschedules a booking.
   * @param bookingId The booking ID.
   * @param data The booking info.
   * @returns {Promise<NylasResponse<NylasEvent>>}
   */
  public async rescheduleBooking(bookingId: string, data: NylasSchedulerBookingDataWithFlatFields): Promise<NylasSchedulerResponse<NylasEvent>> {
    this.schedulerStore.set('isLoading', true);
    if (!bookingId) {
      return { error: this.errors.component(i18next.t('rescheduleBookingErrorTitle')).no_booking_id() };
    }
    const apiErrors = this.errors.api(i18next.t('rescheduleBookingErrorTitle'));
    const componentErrors = this.errors.component(i18next.t('rescheduleBookingErrorTitle'));
    const { bookingInfo, selectedTimeslot, selectedTimezone, selectedLanguage } = this.schedulerStore.state;
    // Validate data
    const { startTime, endTime, timezone } = data;
    const start_time = startTime || selectedTimeslot?.start_time;

    if (!start_time) {
      return { error: componentErrors.invalid_start_time('Please pass "startTime" in data or set "selectedTimeslot" in the defaultSchedulerState.') };
    }
    const end_time = endTime || selectedTimeslot?.end_time;
    if (!end_time) {
      return { error: componentErrors.invalid_end_time('Please pass "endTime" in data or set "selectedTimeslot" in the defaultSchedulerState.') };
    }

    const order = this.schedulerStore.get('availabilityOrderEmails');
    let participantToBookWith = '';
    if (order.length > 0) {
      // Get the emails in the timeslot
      const emails = selectedTimeslot?.emails || [];
      for (let i = 0; i < order.length; i++) {
        if (emails.includes(order[i])) {
          participantToBookWith = order[i];
          break;
        }
      }
    }

    const time_zone = timezone || selectedTimezone;
    if (!time_zone) {
      return { error: componentErrors.invalid_timezone('Please pass "timezone" in data or set "selectedTimezone" in the defaultSchedulerState.') };
    }
    const addFields = {};
    Object.entries(bookingInfo?.additionalFields || {}).forEach(([key, entry]) => {
      addFields[key] = (entry as { value: string; type?: string }).value;
    });
    const primaryGuest = data ? data?.primaryParticipant : bookingInfo?.primaryParticipant;
    const guests = data ? data?.guests || [] : bookingInfo?.guests || [];
    const additional_fields = data ? data?.additionalFields : addFields;

    const configIdParam =
      !this.sessionId && this.configId
        ? `?configuration_id=${this.configId}`
        : !this.sessionId && this.slug && this.clientId
          ? `?slug=${this.slug}&client_id=${this.clientId}`
          : '';
    const url = `/v3/scheduling/bookings/${bookingId}${configIdParam}`;
    const headers = this.getHeaders();
    const response = await this.makeAPIRequest<NylasEvent>(
      decodeURIComponent(url),
      'PATCH',
      JSON.stringify({
        start_time: start_time.getTime() / 1000,
        end_time: end_time.getTime() / 1000,
        timezone: time_zone,
        additional_fields,
        guest: { ...primaryGuest },
        additional_guests: guests,
        participants: participantToBookWith ? [{ email: participantToBookWith }] : undefined,
        email_language: this.getTwoLetterLanguageCode(selectedLanguage),
      }),
      headers,
    );

    if ('error' in response) {
      this.schedulerStore.set('isLoading', false);
      const errorType = response.error?.type;
      let error = response.error;
      if (errorType && errorType in apiErrors) {
        const errorMessage = this.getErrorMessage(error);
        error = apiErrors[errorType as APIErrorType](errorMessage);
      }
      return { error };
    }

    const eventInfo = this.schedulerStore.get('reschedulingEventInfo');
    if ('data' in response) {
      this.schedulerStore.set('eventInfo', response?.data);
    } else if (eventInfo) {
      this.schedulerStore.set('eventInfo', eventInfo);
    } else {
      // We should technically never reach this point
      const event = {
        booking_id: bookingId,
      } as NylasEvent;
      this.schedulerStore.set('eventInfo', event);
    }

    this.schedulerStore.set('isLoading', false);
    return response;
  }

  /**
   * Updates the booking.
   * @param bookingId The booking ID.
   */
  public async updateBooking(payload: { bookingId: string; status: 'confirmed' | 'cancelled'; reason?: string }): Promise<NylasSchedulerResponse<NylasEvent>> {
    this.schedulerStore.set('isLoading', true);
    const { bookingId, status, reason } = payload;
    const salt = this.schedulerStore.get('organizerConfirmationSalt');
    const errorTitle = status === 'confirmed' ? i18next.t('confirmBookingErrorTitle') : i18next.t('rejectBookingErrorTitle');

    if (!bookingId) {
      return { error: this.errors.component(errorTitle).no_booking_id() };
    }
    if (!salt) {
      return { error: this.errors.component(errorTitle).no_salt() };
    }

    const configIdParam =
      !this.sessionId && this.configId
        ? `?configuration_id=${this.configId}`
        : !this.sessionId && this.slug && this.clientId
          ? `?slug=${this.slug}&client_id=${this.clientId}`
          : '';
    const url = `/v3/scheduling/bookings/${bookingId}${configIdParam}`;
    const headers = this.getHeaders();
    const response = await this.makeAPIRequest<NylasEvent>(
      decodeURIComponent(url),
      'PUT',
      JSON.stringify({
        status: status,
        cancellation_reason: reason,
        salt,
      }),
      headers,
    );

    if ('error' in response) {
      this.schedulerStore.set('isLoading', false);
      const errorType = response.error?.type;
      let error = response.error;
      if (errorType && errorType in this.errors.api(errorTitle)) {
        const errorMessage = this.getErrorMessage(error);
        error = this.errors.api(errorTitle)[errorType as APIErrorType](errorMessage);
      }
      return { error };
    }

    if ('data' in response && status === 'confirmed') {
      this.schedulerStore.set('confirmedEventInfo', response?.data);
    } else if ('request_id' in response && status === 'cancelled') {
      this.schedulerStore.set('cancelledEventInfo', {
        booking_id: bookingId,
      });
    }

    this.schedulerStore.set('organizerConfirmationBookingId', '');
    this.schedulerStore.set('isLoading', false);
    return response;
  }
}
