import { DataState, NylasEvent } from '@/common/types';
import { debug } from '@/utils/utils';
import { AdditionalFields, Appearance, NylasSchedulerBookingData, ThemeConfig, Timeslot } from '@nylas/core';
import { createStore } from '@stencil/store';

export type AvailabilityTimeslot = {
  emails: string[];
  start_time: Date;
  end_time: Date;
};

export type LoadingState = {
  api: 'availability' | 'createBooking' | 'cancelBooking' | 'rescheduleBooking';
};

export type ConfigSettings = {
  configuration_id: string;
  scheduler: {
    available_days_in_future: number;
    min_cancellation_notice: number;
    min_booking_notice: number;
    rescheduling_url?: string;
    cancellation_url?: string;
    cancellation_policy?: string;
    hide_additional_guests?: boolean;
    hide_cancellation_options?: boolean;
    hide_rescheduling_options?: boolean;
    additional_fields?: Record<string, AdditionalFields>;
    confirmation_redirect_url?: string;
    organizer_confirmation_url?: string;
  };
  organizer: {
    name: string;
    email: string;
  };
  slug: string;
  appearance: Appearance;
  booking_type: string;
  name: string;
};

export interface NylasSchedulerStoreState {
  selectedDate: Date | null;
  selectedLanguage: string;
  selectedTimezone: string;
  selectedTimeslot: Timeslot | null;
  availabilityOrderEmails: string[];
  showBookingForm: boolean;
  selectableDates: Date[] | null;
  eventDuration: number;
  availability: AvailabilityTimeslot[];
  state: DataState;
  eventInfo: NylasEvent | null;
  cancelledEventInfo: Partial<NylasEvent> | null;
  reschedulingEventInfo?: NylasEvent;
  confirmedEventInfo?: NylasEvent;
  bookingInfo?: NylasSchedulerBookingData;
  rescheduleBookingId?: string;
  cancelBookingId?: string;
  isLoading: boolean;
  nylasBranding?: boolean;
  configSettings?: ConfigSettings;
  organizerConfirmationBookingId?: string;
  rejectBookingId?: string;
  organizerConfirmationSalt?: string;
  themeConfig?: ThemeConfig;
}

export type NylasSchedulerStoreType = ReturnType<typeof CreateNylasSchedulerStore>;

export function CreateNylasSchedulerStore(defaultState: Partial<NylasSchedulerStoreState> = {}) {
  const defaultNylasStoreState: NylasSchedulerStoreState = {
    selectedDate: null,
    selectedLanguage: navigator.language,
    selectedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    selectedTimeslot: null,
    showBookingForm: false,
    availabilityOrderEmails: [],
    selectableDates: null,
    availability: [],
    eventDuration: 0,
    state: 'ready',
    eventInfo: null,
    cancelledEventInfo: null,
    isLoading: false,
    nylasBranding: true,
    ...defaultState,
  };
  debug(`[defaultNylasStoreState]: `, defaultNylasStoreState);
  const store = createStore<NylasSchedulerStoreState>(defaultNylasStoreState);

  store.onChange('availability', availability => {
    debug(`[availability]: `, availability);
    const selectableDates = availability.map(timeslot => timeslot.start_time);
    debug(`[selectableDates]: `, selectableDates);
    store.set('selectableDates', selectableDates);
    const timeslot = availability[0];
    if (!timeslot) return;
    const durationMinutes = Math.floor((timeslot.end_time.getTime() - timeslot.start_time.getTime()) / 60000);
    debug(`[durationMinutes]: `, durationMinutes);
    store.set('eventDuration', durationMinutes);
  });

  /**
   * Reset the Nylas store to its default state.
   * There is something wrong with the stencil/store reset method,
   * so we have to do it via this hack.
   */
  store.reset = () => {
    for (const key in defaultNylasStoreState) {
      const value = defaultNylasStoreState[key as keyof typeof defaultNylasStoreState];
      store.set(key as any, value);
    }
  };

  return store;
}
