import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'refresh-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class RefreshIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      <svg fill="auto" width={this.width} height={this.height} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.544 28.080c-0.002-0.006-0.008-0.010-0.012-0.015l-2.873-4.489c-0.204-0.335-0.646-0.454-0.992-0.266l-0.256 0.157c-0.344 0.188-0.337 0.673-0.133 1.008l1.841 2.857c-0.157-0.035-0.316-0.063-0.471-0.103-3.224-0.843-5.953-3.026-7.485-5.988-1.448-2.797-1.72-5.99-0.766-8.992s3.118-5.452 5.915-6.899c0.476-0.247 0.662-0.832 0.416-1.308-0.246-0.477-0.832-0.663-1.308-0.416-3.258 1.686-5.763 4.54-6.874 8.036s-0.794 7.215 0.892 10.473c1.786 3.448 4.963 5.989 8.72 6.973 0.043 0.011 0.087 0.017 0.13 0.028l-2.541 1.288c-0.344 0.189-0.458 0.613-0.254 0.948l0.098 0.256c0.205 0.335 0.557 0.454 0.9 0.266l4.651-2.381c0.006-0.004 0.012-0.003 0.018-0.007l0.312-0.171c0.172-0.095 0.287-0.249 0.332-0.422 0.047-0.172 0.025-0.364-0.076-0.531zM28.559 10.025c-1.783-3.447-4.862-5.988-8.618-6.972-0.267-0.070-0.541-0.124-0.814-0.179l2.494-1.265c0.344-0.189 0.549-0.614 0.345-0.949l-0.099-0.255c-0.205-0.336-0.648-0.454-0.991-0.267l-4.651 2.381c-0.006 0.003-0.012 0.002-0.018 0.006l-0.312 0.171c-0.173 0.095-0.287 0.249-0.332 0.422-0.047 0.172-0.025 0.364 0.077 0.53l0.185 0.304c0.003 0.006 0.008 0.010 0.012 0.016l2.873 4.489c0.203 0.335 0.646 0.454 0.991 0.266l0.226-0.157c0.344-0.188 0.366-0.673 0.163-1.008l-1.85-2.87c0.407 0.063 0.811 0.138 1.207 0.242 3.226 0.845 5.856 3.027 7.387 5.986 1.448 2.797 1.72 5.99 0.765 8.991s-3.020 5.451-5.818 6.901c-0.476 0.247-0.662 0.831-0.415 1.308 0.172 0.332 0.511 0.524 0.863 0.524 0.15 0 0.302-0.035 0.446-0.109 3.259-1.686 5.664-4.54 6.776-8.035 1.111-3.497 0.794-7.217-0.893-10.473z"></path>
      </svg>
    );
  }
}
