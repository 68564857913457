import { Component, Prop, h } from '@stencil/core';

@Component({
  tag: 'reply-icon',
  styleUrl: 'icon.css',
  scoped: true,
})
export class ReplyIcon {
  @Prop() width: string = '15';
  @Prop() height: string = '15';

  render() {
    return (
      <svg fill="auto" width={this.width} height={this.height} viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M835.942 632.563H244.966l478.08-478.08-90.496-90.496L-.026 696.563 632.55 1329.14l90.496-90.496-478.08-478.08h590.976c504.448 0 914.816 410.368 914.816 914.816v109.184h128V1675.38c0-574.976-467.84-1042.816-1042.816-1042.816"
          fill-rule="evenodd"
        />
      </svg>
    );
  }
}
