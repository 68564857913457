import { Component, Element, Event, EventEmitter, h, Listen, Prop, State, Watch } from '@stencil/core';

interface DropdownOption {
  labelHTML?: HTMLElement;
  label: string;
  value: string;
}

/**
 * The `select-dropdown` component is a dropdown that allows users to select an option from a list of options.
 * This component is used in the scheduling form to input dropdown type inputs.
 * @part sd_dropdown - The dropdown container
 * @part sd_dropdown-button - The dropdown button
 * @part sd_dropdown-button-selected-label - The selected option label
 * @part sd_dropdown-content - The dropdown content
 * @part sd_dropdown_label - The dropdown label
 */
@Component({
  tag: 'select-dropdown',
  styleUrl: 'select-dropdown.scss',
  shadow: true,
})
export class SelectDropdown {
  @Element() el!: HTMLElement;
  private readonly componentType: string = 'select-dropdown';

  private inputRef?: HTMLInputElement;

  // Props
  /**
   * The name of the dropdown
   */
  @Prop() name!: string;
  /**
   * The options to display in the dropdown
   */
  @Prop() options: DropdownOption[] = [];
  /**
   * The default selected option
   */
  @Prop({ attribute: 'default-selected-option' }) defaultSelectedOption: DropdownOption | null = null;
  /**
   * Should show search input
   */
  @Prop() withSearch: boolean = true;

  /**
   * The label for the dropdown, skipped if no label is provided
   */
  @Prop() label?: string;

  /**
   * If true, the dropdown is required for form submission
   */
  @Prop() required: boolean = false;

  /**
   * Show pluralized label for the selected option. This is s tring that is appended to the selected option label as a suffix.
   */
  @Prop() pluralizedLabel: string = '';
  /**
   * Overrides the select dropdown to be used as a button with dropdownButtonText representing actions &  dropdownText name on the dropdown intead of selected value
   */
  @Prop() dropdownButtonText?: string;
  /**
   * Should show chevron on button
   */
  @Prop() withChevron: boolean = true;
  /**
   * Allows to set the empty value of the dropdown
   */
  @Prop() emptyValue: string = 'Select an option'; // Default empty value
  /**
   * The custom error message to display when the value is empty or null and the dropdown is required
   */
  @Prop() errorMessage: string = '';

  // States
  /**
   * The selected option
   */
  @State() selectedOption!: DropdownOption | null;
  /**
   * The open state of the dropdown
   */
  @State() isOpen: boolean = false;
  /**
   * The search value for the dropdown
   */
  @State() searchValue: string = '';
  /**
   * The filtered options based on the search value
   */
  @State() filteredOptions: DropdownOption[] = [...this.options];
  /**
   * The aria-activedescendant attribute for the listbox element to indicate the currently active
   * option in the list box to screen readers. The value of aria-activedescendant is the ID of
   * the active option.
   */
  @State() ariaActivedescendant: string = '';

  /**
   * The error message to display when the value is empty or null and the dropdown is required
   */
  @State() error: string = '';

  // Events
  /**
   * This event is fired when the selected option is changed
   */
  @Event({ bubbles: true, composed: true }) nylasFormDropdownChanged!: EventEmitter<{
    value: DropdownOption['value'];
    name: string;
    error?: string;
    label?: string;
  }>;

  /**
   * This event is fired when the default selected option is set, on component load
   */
  @Event({ bubbles: true, composed: true }) nylasFormDropdownDefaultSelected!: EventEmitter<{
    value: DropdownOption['value'];
    name: string;
    error?: string;
    label?: string;
  }>;

  @Watch('options')
  optionsChangedHandler(newValue: DropdownOption[], oldValue: DropdownOption[]) {
    if (newValue === oldValue) {
      return;
    }
    this.filteredOptions = newValue;
  }

  @Watch('defaultSelectedOption')
  defaultSelectedOptionChangedHandler(newValue: DropdownOption, oldValue: DropdownOption) {
    if (typeof newValue === 'undefined' || newValue?.label === oldValue?.label) {
      return;
    }
    this.selectedOption = newValue;
    this.nylasFormDropdownDefaultSelected.emit({
      value: newValue?.value || '',
      name: this.name,
      error: this.error,
      label: this.label,
    });
  }

  // Lifecycle methods
  componentWillLoad() {
    this.el.setAttribute('component-type', this.componentType);
  }

  componentDidLoad() {
    this.filteredOptions = this.options;
    // Set the selected option to the first option if no option is selected
    this.selectedOption = this.defaultSelectedOption;

    if (!this.selectedOption && this.options.length > 0) {
      this.selectedOption = this.options[0];
    }
    this.nylasFormDropdownDefaultSelected.emit({
      value: this.selectedOption?.value || '',
      name: this.name,
      error: this.error,
      label: this.label,
    });
  }

  // Event listeners
  /**
   * Listen for the bookingFormSubmitted event to validate the input value when the form is submitted.
   */
  @Listen('bookingFormSubmitted', { target: 'document' })
  handleBookingFormSubmitted(event: CustomEvent) {
    this.validate(this.selectedOption?.value || '');
    if (this.error) {
      event.preventDefault();
    }
  }

  /**
   * Listen for the formSubmitted event to validate the input value when the form is submitted.
   * @param event
   */
  @Listen('formSubmitted', { target: 'document' })
  handleFormSubmitted(event: CustomEvent) {
    this.validate(this.selectedOption?.value || '');
    if (this.error) {
      event.preventDefault();
    }
  }

  // Methods
  validate(value: string) {
    if (this.required && !value) {
      this.error = this.errorMessage || `${this.label} is required.`;
    } else {
      this.error = '';
    }
  }
  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  filterOptions(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.searchValue = value;
    this.filteredOptions = this.options.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
  }

  selectOption(option: DropdownOption): void {
    this.error = '';
    this.selectedOption = option;
    this.toggleDropdown();
    if (option.value !== this.emptyValue) {
      this.nylasFormDropdownChanged.emit({
        value: option.value,
        name: this.name,
        error: this.error,
        label: this.label,
      });
    }
  }

  handleSelectButtonKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
      case 'Enter':
        event.preventDefault();
        if (!this.isOpen) {
          this.toggleDropdown();
        }
        this.inputRef?.focus();
        break;
      case 'Escape':
        this.isOpen = false;
        break;
    }
  }

  handleListboxKeydown(e) {
    const items = this.filteredOptions; // Assuming this is the array of your current filtered options
    const currentIndex = items.findIndex(item => item.value === this.ariaActivedescendant);
    if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)) {
      e.preventDefault();
      if (currentIndex === items.length - 1) {
        this.ariaActivedescendant = '';
        this.inputRef?.focus();
        return;
      }
      const nextIndex = currentIndex + 1 < items.length ? currentIndex + 1 : 0;
      this.ariaActivedescendant = items[nextIndex].value;
      this.focusOption(nextIndex);
    } else if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)) {
      e.preventDefault();
      if (currentIndex === 0) {
        this.ariaActivedescendant = '';
        this.inputRef?.focus();
        return;
      }
      const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1;
      this.ariaActivedescendant = items[prevIndex].value;
      this.focusOption(prevIndex);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (this.ariaActivedescendant) {
        this.selectOption(items[currentIndex]);
      }
    } else if (e.key === 'Escape') {
      this.isOpen = false;
    }
  }

  focusOption(index) {
    const option = this.filteredOptions[index];
    if (!option) return; // Guard clause in case index is out of bounds

    const elementId = option.value;
    const element = this.el.shadowRoot?.getElementById(elementId) as HTMLLIElement;

    if (element) {
      element.focus(); // Set focus on the element
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  handleComboboxKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' || (event.key == 'Tab' && !event.shiftKey)) {
      event.preventDefault();
      this.ariaActivedescendant = this.filteredOptions[0].value;
      this.focusOption(0);
    } else if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
      event.preventDefault();
      this.ariaActivedescendant = this.filteredOptions[this.filteredOptions.length - 1].value;
      this.focusOption(this.filteredOptions.length - 1);
    } else if (event.key === 'Escape') {
      this.isOpen = false;
    }
  }

  generateButtonText(option: DropdownOption | null, dropButtonText?: string): string {
    if (dropButtonText) {
      return dropButtonText;
    }
    return option?.label ? `${option?.label + this.pluralizedLabel}` : this.emptyValue;
  }

  // Event listeners
  @Listen('click', { target: 'document', capture: true })
  handleOutsideClick(event: MouseEvent) {
    // Get the path of the event
    const path = event.composedPath();

    // Check if the path includes the host element
    const isClickInside = path.includes(this.el);

    if (!isClickInside && this.isOpen) {
      this.isOpen = false;
    }
  }

  render() {
    const buttonText = this.generateButtonText(this.selectedOption, this.dropdownButtonText);

    return (
      <div class="dropdown" part="sd_dropdown">
        <label part="sd_dropdown_label" class={{ error: !!this.error }}>
          {this.label && (
            <p>
              <span class="label">{this.label}</span>
              {this.required && <span class="required">*</span>}
            </p>
          )}
          <button
            part="sd_dropdown-button"
            class={{ dropbtn: true, open: this.isOpen, error: !!this.error }}
            onClick={() => this.toggleDropdown()}
            aria-haspopup="listbox"
            aria-expanded={this.isOpen ? 'true' : 'false'}
            aria-label={this.name}
            onKeyDown={e => this.handleSelectButtonKeyDown(e)}
          >
            <slot name="select-icon" aria-hidden="true"></slot>
            <span class="selected-option" part="sd_dropdown-button-selected-label">
              {buttonText}
            </span>
            {this.withChevron && (
              <span
                class={{
                  open: this.isOpen,
                  closed: !this.isOpen,
                  chevron: true,
                }}
                aria-hidden="true"
              >
                <chevron-icon width="16" height="16" />
              </span>
            )}
          </button>
          {this.error && <span class="error help-text">{this.error}</span>}
        </label>

        {this.isOpen ? (
          <div class="dropdown-content" part="sd_dropdown-content">
            {this.withSearch && (
              <div class={{ 'search-box': true, 'open': this.isOpen }}>
                <search-icon width="15" height="15" class={'icon'} />
                <input
                  type="text"
                  role="combobox"
                  placeholder="Search"
                  value={this.searchValue}
                  ref={el => (this.inputRef = el)}
                  onInput={event => this.filterOptions(event)}
                  onKeyDown={e => this.handleComboboxKeyDown(e)}
                />
              </div>
            )}
            <ul tabindex="-1" role="listbox" aria-label={this.name} aria-activedescendant={this.ariaActivedescendant} onKeyDown={e => this.handleListboxKeydown(e)}>
              {this.filteredOptions.map(option =>
                option.value.toString() ? (
                  <li tabindex="0" key={option.value} id={option.value} onClick={() => this.selectOption(option)} role="option">
                    {option.labelHTML ? <div part="sd_dropdown-labelhtml">{option.labelHTML}</div> : option.label}
                  </li>
                ) : null,
              )}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
