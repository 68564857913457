export enum ErrorCategory {
  Component = 'component',
  Api = 'api',
  Auth = 'auth',
}

export interface ErrorDetails {
  title: string;
  message: string;
  category: ErrorCategory;
}

export enum ComponentErrorType {
  endtime_not_in_future = 'endtime_not_in_future',
  no_booking_info = 'no_booking_info',
  no_timeslot_selected = 'no_timeslot_selected',
  no_timezone_selected = 'no_timezone_selected',
  no_booking_id = 'no_booking_id',
  no_salt = 'no_salt',
  invalid_start_time = 'invalid_start_time',
  invalid_end_time = 'invalid_end_time',
  invalid_timezone = 'invalid_timezone',
}

export enum APIErrorType {
  invalid_session = 'invalid_session',
  general_error = 'general_error',
  internal_error = 'internal_error',
  invalid_request_error = 'invalid_request_error',
  timeslot_not_available = 'timeslot_not_available',
  provider_error = 'provider_error',
  not_found_error = 'not_found_error',
}

export class Errors {
  component = (_title: string): Record<ComponentErrorType, (message?: string) => ErrorDetails> => {
    const category = ErrorCategory.Component;
    const title = `${_title} Error`;
    return {
      endtime_not_in_future: (message: string = '"endtime" can not be in the future') => {
        return {
          title,
          message,
          category,
        };
      },
      no_booking_info: (message: string = 'No booking info provided') => {
        return {
          title,
          message,
          category,
        };
      },
      no_timeslot_selected: (message: string = 'No timeslot selected') => {
        return {
          title,
          message,
          category,
        };
      },
      no_timezone_selected: (message: string = 'No timezone selected') => {
        return {
          title,
          message,
          category,
        };
      },
      no_booking_id: (message: string = 'No booking id provided') => {
        return {
          title,
          message,
          category,
        };
      },
      no_salt: (message: string = 'No salt provided') => {
        return {
          title,
          message,
          category,
        };
      },
      invalid_start_time: (message: string = 'Invalid start time') => {
        return {
          title,
          message,
          category,
        };
      },
      invalid_end_time: (message: string = 'Invalid end time') => {
        return {
          title,
          message,
          category,
        };
      },
      invalid_timezone: (message: string = 'Invalid timezone') => {
        return {
          title,
          message,
          category,
        };
      },
    };
  };

  api = (_title: string): Record<APIErrorType, (message: string) => ErrorDetails> => {
    const category = ErrorCategory.Api;
    const title = `${_title} Error`;

    return {
      invalid_session: (message: string) => {
        return {
          title,
          message,
          category,
        };
      },
      general_error: (message: string) => {
        return {
          title,
          message,
          category,
        };
      },
      internal_error: (message: string) => {
        return {
          title,
          message,
          category,
        };
      },
      invalid_request_error: (message: string) => {
        return {
          title,
          message,
          category,
        };
      },
      timeslot_not_available: (message: string) => {
        return {
          title,
          message,
          category,
        };
      },
      provider_error: (message: string) => {
        return {
          title,
          message,
          category,
        };
      },
      not_found_error: (message: string) => {
        return {
          title,
          message,
          category,
        };
      },
    };
  };
}
