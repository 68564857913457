@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/inputs.scss';

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include default-css-variables;

  slot[name='label'] {
    @include input-label;
    font-size: 16px;

    p {
      margin: 0;

      &.error {
        color: var(--nylas-error);
      }
    }

    .error {
      color: var(--nylas-error);
    }
  }

  span.error {
    color: var(--nylas-error);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--nylas-font-family);
  }

  label {
    display: flex;

    font-family: var(--nylas-font-family);
    cursor: pointer;

    .radio-option {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;

      input[type='radio'] {
        cursor: pointer;
      }

      input[type='radio' i] {
        width: 20px;
        height: 20px;
        color: var(--nylas-primary);
      }

      p {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin: 0;

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: var(--nylas-base-800);
        }

        .description {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: var(--nylas-base-600);
        }
      }
    }
  }
}
