// src/components/radio-group/radio-group.tsx
import { Component, h, Prop, State, Event, EventEmitter, Listen, Element, Watch } from '@stencil/core';

/**
 * The `radio-button-group` component is a UI component that allows users to select a single option from a list of options.
 * This component is used in the scheduling form to input radio button type inputs.
 */
@Component({
  tag: 'radio-button-group',
  styleUrl: 'radio-button-group.scss',
  shadow: true,
})
export class RadioButtonGroup {
  @Element() el!: HTMLElement;
  private readonly componentType: string = 'radio-group';

  /**
   * The name of the radio group. This is used to identify the radio group when submitting a form.
   */
  @Prop() name: string = 'radio-group';
  /**
   * The list of options in the radio group.
   * Each option has a label and a value and an optional description.
   */
  @Prop() options: { label: string; value: string; description?: string }[] = [];
  /**
   * The default selected value of the radio group.
   */
  @Prop({ attribute: 'default-selected-value' }) defaultSelectedValue: string = '';
  /**
   * The label of the radio group. This is displayed above the radio group.
   * Label is optional. You can also use the slot 'label' to add a label.
   */
  @Prop() label?: string;
  /**
   * Whether the radio group is required. If true, the radio group must have a value when submitting a form.
   * Default is false. If the radio group is required and the value is empty, an error message is displayed.
   */
  @Prop() required: boolean = false;
  /**
   * The selected value of the radio group.
   */
  @State() selectedValue!: string;
  /**
   * The error message state.
   */
  @State() error: string = '';
  /**
   * This event is fired when the selected value changes.
   */
  @Event() nylasFormRadioChanged!: EventEmitter<{
    value: string;
    name: string;
    label?: string;
  }>;

  @Watch('defaultSelectedValue')
  handleDefaultSelectedValueChange(newValue: string) {
    this.selectedValue = newValue;
  }

  // Lifecycle methods
  componentDidLoad() {
    this.selectedValue = this.defaultSelectedValue;
  }

  // Event listeners
  /**
   * Listen for the bookingFormSubmitted event to validate the input value when the form is submitted.
   */
  @Listen('bookingFormSubmitted', { target: 'document' })
  handleBookingFormSubmitted(event: CustomEvent) {
    if (this.required && !this.selectedValue) {
      this.error = `${this.label} is required.`;
      return;
    }
    if (this.error) {
      event.preventDefault();
    }
  }

  componentWillLoad() {
    this.el.setAttribute('component-type', this.componentType);
  }

  handleChange(value: string) {
    this.error = '';
    this.selectedValue = value;
    this.nylasFormRadioChanged.emit({
      value,
      name: this.name,
      label: this.label,
    });
  }

  render() {
    return (
      <div class="radio-group">
        <slot name="label">
          {this.label && (
            <p class={{ error: !!this.error }}>
              <span class="label">{this.label}</span>
              {this.required && <span class="required">*</span>}
            </p>
          )}
        </slot>
        {this.options.map(option => (
          <label>
            <div class="radio-option">
              <input type="radio" name={this.name} value={option.value} checked={this.selectedValue === option.value} onChange={() => this.handleChange(option.value)} />
              <p>
                <span class="title">{option.label}</span>
                <span class="description">{option.description}</span>
              </p>
            </div>
          </label>
        ))}
        {this.error && <span class="error help-text">{this.error}</span>}
      </div>
    );
  }
}
